import React from "react"
import MainLayout from "../components/MainLayout"
import ProductCard from "../components/ProductCard"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import { FloorProductsPageStyles } from "../components/styles/FloorProductPageStyles"
import PageHeading from "../components/PageHeadings"
import DemoSection from "../components/DemoSection"

import K3Image from "../images/KODIAK-k11-17-D-front-WEB-200.png"
import K5Image from "../images/KODIAK-K16-26-D-front-WEB-200.png"
import K7Image from "../images/KODIAK-K24-32-D-front-WEB-200.png"
import K10Image from "../images/K10.png"
import K10Orbitz10Image from "../images/orbitz-10-ac-w200.png"
import K10XImage from "../images/k10X.png"
import Orbitz from "../images/orbitz.png"
import FloorProductCard from "../components/FloorProductCard"

const FloorProductPage = () => {
  return (
    <>
      <SEO title="Floor Scrubber Lineup" />
      <Helmet>
        {/* <title>Kodiak Cleaning Equipment</title> */}
        <description>
          Our floor equipment won't break the bank and will keep scrubbing for
          years. Start scrubbing today!
        </description>
      </Helmet>
      <MainLayout>
        <FloorProductsPageStyles>
          <div>
            <PageHeading
              pageHeading="Walk-Behind Floor Scrubbers"
              // description="Our floor equipment won't break the bank and will keep scrubbing for years.  Start scrubbing today!"
            />

            <div id="product-card-container">
              <div className="card">
                <FloorProductCard
                  id="card"
                  fontSize="18px"
                  image={K10Orbitz10Image}
                  heading='10" Orbital Floor Machine'
                  tank='10.5" Cleaning Width'
                  time="30ft / 18 gauge"
                  size="22 lbs"
                  link="/orbitz-10-NANO"
                ></FloorProductCard>
              </div>
              <div className="card">
                <FloorProductCard
                  id="card"
                  fontSize="18px"
                  image={Orbitz}
                  heading='20" Orbital Floor Machine'
                  tank='20" Cleaning Width'
                  time="50ft / 14 gauge"
                  size="144 - 232 lbs"
                  link="/orbitz-20-floor-machine"
                ></FloorProductCard>
              </div>
            </div>
          </div>
        </FloorProductsPageStyles>
        <DemoSection />
      </MainLayout>
    </>
  )
}
export default FloorProductPage
